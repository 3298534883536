/**
 * Global */
@font-face {
  font-family: 'Mana';
  src: url('../fonts/mana.eot?v=1.6.0');
  src: url('../fonts/mana.eot?#iefix&v=1.6.0') format('embedded-opentype'), url('../fonts/mana.woff?v=1.6.0') format('woff'), url('../fonts/mana.ttf?v=1.6.0') format('truetype'), url('../fonts/mana.svg?v=1.6.0#mana') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MPlantin';
  src: url('../fonts/mplantin.eot?v=1.6.0');
  src: url('../fonts/mplantin.eot?#iefix&v=1.6.0') format('embedded-opentype'), url('../fonts/mplantin.woff?v=1.6.0') format('woff'), url('../fonts/mplantin.ttf?v=1.6.0') format('truetype'), url('../fonts/mplantin.svg?v=1.6.0#mplantin') format('svg');
  font-weight: normal;
  font-style: normal;
}
.ms {
  display: inline-block;
  font: normal normal normal 14px/1 Mana;
  font-size: inherit;
  line-height: 1em;
  text-rendering: auto;
  transform: translate(0, 0);
  speak: none;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/**
 * Mana cost styles */
.ms-cost {
  background-color: #BEB9B2;
  border-radius: 1em;
  color: #111;
  font-size: 0.95em;
  width: 1.3em;
  height: 1.3em;
  line-height: 1.35em;
  text-align: center;
}
.ms-cost.ms-w,
.ms-cost.ms-wp {
  background-color: #F0F2C0;
}
.ms-cost.ms-u,
.ms-cost.ms-up {
  background-color: #B5CDE3;
}
.ms-cost.ms-b,
.ms-cost.ms-bp {
  background-color: #ACA29A;
}
.ms-cost.ms-r,
.ms-cost.ms-rp {
  background-color: #DB8664;
}
.ms-cost.ms-g,
.ms-cost.ms-gp {
  background-color: #93B483;
}
.ms-cost.ms-wu,
.ms-cost.ms-wb,
.ms-cost.ms-ub,
.ms-cost.ms-ur,
.ms-cost.ms-br,
.ms-cost.ms-bg,
.ms-cost.ms-rw,
.ms-cost.ms-rg,
.ms-cost.ms-gw,
.ms-cost.ms-gu,
.ms-cost.ms-2w,
.ms-cost.ms-2u,
.ms-cost.ms-2b,
.ms-cost.ms-2r,
.ms-cost.ms-2g {
  position: relative;
  width: 1.3em;
  height: 1.3em;
}
.ms-cost.ms-wu:before,
.ms-cost.ms-wb:before,
.ms-cost.ms-ub:before,
.ms-cost.ms-ur:before,
.ms-cost.ms-br:before,
.ms-cost.ms-bg:before,
.ms-cost.ms-rw:before,
.ms-cost.ms-rg:before,
.ms-cost.ms-gw:before,
.ms-cost.ms-gu:before,
.ms-cost.ms-2w:before,
.ms-cost.ms-2u:before,
.ms-cost.ms-2b:before,
.ms-cost.ms-2r:before,
.ms-cost.ms-2g:before,
.ms-cost.ms-wu:after,
.ms-cost.ms-wb:after,
.ms-cost.ms-ub:after,
.ms-cost.ms-ur:after,
.ms-cost.ms-br:after,
.ms-cost.ms-bg:after,
.ms-cost.ms-rw:after,
.ms-cost.ms-rg:after,
.ms-cost.ms-gw:after,
.ms-cost.ms-gu:after,
.ms-cost.ms-2w:after,
.ms-cost.ms-2u:after,
.ms-cost.ms-2b:after,
.ms-cost.ms-2r:after,
.ms-cost.ms-2g:after {
  font-size: 0.55em !important;
  position: absolute;
}
.ms-cost.ms-wu:before,
.ms-cost.ms-wb:before,
.ms-cost.ms-ub:before,
.ms-cost.ms-ur:before,
.ms-cost.ms-br:before,
.ms-cost.ms-bg:before,
.ms-cost.ms-rw:before,
.ms-cost.ms-rg:before,
.ms-cost.ms-gw:before,
.ms-cost.ms-gu:before,
.ms-cost.ms-2w:before,
.ms-cost.ms-2u:before,
.ms-cost.ms-2b:before,
.ms-cost.ms-2r:before,
.ms-cost.ms-2g:before {
  top: -0.38em;
  left: 0.28em;
}
.ms-cost.ms-wu:after,
.ms-cost.ms-wb:after,
.ms-cost.ms-ub:after,
.ms-cost.ms-ur:after,
.ms-cost.ms-br:after,
.ms-cost.ms-bg:after,
.ms-cost.ms-rw:after,
.ms-cost.ms-rg:after,
.ms-cost.ms-gw:after,
.ms-cost.ms-gu:after,
.ms-cost.ms-2w:after,
.ms-cost.ms-2u:after,
.ms-cost.ms-2b:after,
.ms-cost.ms-2r:after,
.ms-cost.ms-2g:after {
  top: 0.5em;
  left: 1em;
}
.ms-cost.ms-wu {
  background: #edf2b0;
  background: -moz-linear-gradient(-45deg, #edf2b0 0%, #edf2b0 50%, #a6c1dd 50%, #a6c1dd 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #edf2b0), color-stop(50%, #edf2b0), color-stop(50%, #a6c1dd), color-stop(100%, #a6c1dd));
  background: -webkit-linear-gradient(-45deg, #edf2b0 0%, #edf2b0 50%, #a6c1dd 50%, #a6c1dd 100%);
  background: -o-linear-gradient(-45deg, #edf2b0 0%, #edf2b0 50%, #a6c1dd 50%, #a6c1dd 100%);
  background: -ms-linear-gradient(-45deg, #edf2b0 0%, #edf2b0 50%, #a6c1dd 50%, #a6c1dd 100%);
  background: linear-gradient(135deg, #edf2b0 0%, #edf2b0 50%, #a6c1dd 50%, #a6c1dd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#edf2b0', endColorstr='#a6c1dd', GradientType=1);
}
.ms-cost.ms-wb {
  background: #edf2b0;
  background: -moz-linear-gradient(-45deg, #edf2b0 0%, #edf2b0 50%, #9c9188 50%, #9c9188 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #edf2b0), color-stop(50%, #edf2b0), color-stop(50%, #9c9188), color-stop(100%, #9c9188));
  background: -webkit-linear-gradient(-45deg, #edf2b0 0%, #edf2b0 50%, #9c9188 50%, #9c9188 100%);
  background: -o-linear-gradient(-45deg, #edf2b0 0%, #edf2b0 50%, #9c9188 50%, #9c9188 100%);
  background: -ms-linear-gradient(-45deg, #edf2b0 0%, #edf2b0 50%, #9c9188 50%, #9c9188 100%);
  background: linear-gradient(135deg, #edf2b0 0%, #edf2b0 50%, #9c9188 50%, #9c9188 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#edf2b0', endColorstr='#9c9188', GradientType=1);
}
.ms-cost.ms-ub {
  background: #a6c1dd;
  background: -moz-linear-gradient(-45deg, #a6c1dd 0%, #a6c1dd 50%, #9c9188 50%, #9c9188 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #a6c1dd), color-stop(50%, #a6c1dd), color-stop(50%, #9c9188), color-stop(100%, #9c9188));
  background: -webkit-linear-gradient(-45deg, #a6c1dd 0%, #a6c1dd 50%, #9c9188 50%, #9c9188 100%);
  background: -o-linear-gradient(-45deg, #a6c1dd 0%, #a6c1dd 50%, #9c9188 50%, #9c9188 100%);
  background: -ms-linear-gradient(-45deg, #a6c1dd 0%, #a6c1dd 50%, #9c9188 50%, #9c9188 100%);
  background: linear-gradient(135deg, #a6c1dd 0%, #a6c1dd 50%, #9c9188 50%, #9c9188 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6c1dd', endColorstr='#9c9188', GradientType=1);
}
.ms-cost.ms-ur {
  background: #a6c1dd;
  background: -moz-linear-gradient(-45deg, #a6c1dd 0%, #a6c1dd 50%, #db8664 50%, #db8664 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #a6c1dd), color-stop(50%, #a6c1dd), color-stop(50%, #db8664), color-stop(100%, #db8664));
  background: -webkit-linear-gradient(-45deg, #a6c1dd 0%, #a6c1dd 50%, #db8664 50%, #db8664 100%);
  background: -o-linear-gradient(-45deg, #a6c1dd 0%, #a6c1dd 50%, #db8664 50%, #db8664 100%);
  background: -ms-linear-gradient(-45deg, #a6c1dd 0%, #a6c1dd 50%, #db8664 50%, #db8664 100%);
  background: linear-gradient(135deg, #a6c1dd 0%, #a6c1dd 50%, #db8664 50%, #db8664 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6c1dd', endColorstr='#db8664', GradientType=1);
}
.ms-cost.ms-br {
  background: #aca29a;
  background: -moz-linear-gradient(-45deg, #aca29a 0%, #aca29a 50%, #db8664 50%, #db8664 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #aca29a), color-stop(50%, #aca29a), color-stop(50%, #db8664), color-stop(100%, #db8664));
  background: -webkit-linear-gradient(-45deg, #aca29a 0%, #aca29a 50%, #db8664 50%, #db8664 100%);
  background: -o-linear-gradient(-45deg, #aca29a 0%, #aca29a 50%, #db8664 50%, #db8664 100%);
  background: -ms-linear-gradient(-45deg, #aca29a 0%, #aca29a 50%, #db8664 50%, #db8664 100%);
  background: linear-gradient(135deg, #aca29a 0%, #aca29a 50%, #db8664 50%, #db8664 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#aca29a', endColorstr='#db8664', GradientType=1);
}
.ms-cost.ms-bg {
  background: #aca29a;
  background: -moz-linear-gradient(-45deg, #aca29a 0%, #aca29a 50%, #93b483 50%, #93b483 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #aca29a), color-stop(50%, #aca29a), color-stop(50%, #93b483), color-stop(100%, #93b483));
  background: -webkit-linear-gradient(-45deg, #aca29a 0%, #aca29a 50%, #93b483 50%, #93b483 100%);
  background: -o-linear-gradient(-45deg, #aca29a 0%, #aca29a 50%, #93b483 50%, #93b483 100%);
  background: -ms-linear-gradient(-45deg, #aca29a 0%, #aca29a 50%, #93b483 50%, #93b483 100%);
  background: linear-gradient(135deg, #aca29a 0%, #aca29a 50%, #93b483 50%, #93b483 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#aca29a', endColorstr='#93b483', GradientType=1);
}
.ms-cost.ms-rw {
  background: #db8664;
  background: -moz-linear-gradient(-45deg, #db8664 0%, #db8664 50%, #edf2b0 50%, #edf2b0 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #db8664), color-stop(50%, #db8664), color-stop(50%, #edf2b0), color-stop(100%, #edf2b0));
  background: -webkit-linear-gradient(-45deg, #db8664 0%, #db8664 50%, #edf2b0 50%, #edf2b0 100%);
  background: -o-linear-gradient(-45deg, #db8664 0%, #db8664 50%, #edf2b0 50%, #edf2b0 100%);
  background: -ms-linear-gradient(-45deg, #db8664 0%, #db8664 50%, #edf2b0 50%, #edf2b0 100%);
  background: linear-gradient(135deg, #db8664 0%, #db8664 50%, #edf2b0 50%, #edf2b0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#db8664', endColorstr='#edf2b0', GradientType=1);
}
.ms-cost.ms-rg {
  background: #db8664;
  background: -moz-linear-gradient(-45deg, #db8664 0%, #db8664 50%, #93b483 50%, #93b483 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #db8664), color-stop(50%, #db8664), color-stop(50%, #93b483), color-stop(100%, #93b483));
  background: -webkit-linear-gradient(-45deg, #db8664 0%, #db8664 50%, #93b483 50%, #93b483 100%);
  background: -o-linear-gradient(-45deg, #db8664 0%, #db8664 50%, #93b483 50%, #93b483 100%);
  background: -ms-linear-gradient(-45deg, #db8664 0%, #db8664 50%, #93b483 50%, #93b483 100%);
  background: linear-gradient(135deg, #db8664 0%, #db8664 50%, #93b483 50%, #93b483 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#db8664', endColorstr='#93b483', GradientType=1);
}
.ms-cost.ms-gw {
  background: #93b483;
  background: -moz-linear-gradient(-45deg, #93b483 0%, #93b483 50%, #edf2b0 50%, #edf2b0 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #93b483), color-stop(50%, #93b483), color-stop(50%, #edf2b0), color-stop(100%, #edf2b0));
  background: -webkit-linear-gradient(-45deg, #93b483 0%, #93b483 50%, #edf2b0 50%, #edf2b0 100%);
  background: -o-linear-gradient(-45deg, #93b483 0%, #93b483 50%, #edf2b0 50%, #edf2b0 100%);
  background: -ms-linear-gradient(-45deg, #93b483 0%, #93b483 50%, #edf2b0 50%, #edf2b0 100%);
  background: linear-gradient(135deg, #93b483 0%, #93b483 50%, #edf2b0 50%, #edf2b0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#93b483', endColorstr='#edf2b0', GradientType=1);
}
.ms-cost.ms-gu {
  background: #93b483;
  background: -moz-linear-gradient(-45deg, #93b483 0%, #93b483 50%, #b5cde3 50%, #b5cde3 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #93b483), color-stop(50%, #93b483), color-stop(50%, #b5cde3), color-stop(100%, #b5cde3));
  background: -webkit-linear-gradient(-45deg, #93b483 0%, #93b483 50%, #b5cde3 50%, #b5cde3 100%);
  background: -o-linear-gradient(-45deg, #93b483 0%, #93b483 50%, #b5cde3 50%, #b5cde3 100%);
  background: -ms-linear-gradient(-45deg, #93b483 0%, #93b483 50%, #b5cde3 50%, #b5cde3 100%);
  background: linear-gradient(135deg, #93b483 0%, #93b483 50%, #b5cde3 50%, #b5cde3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#93b483', endColorstr='#b5cde3', GradientType=1);
}
.ms-cost.ms-2w {
  background: #beb9b2;
  background: -moz-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #edf2b0 50%, #edf2b0 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #beb9b2), color-stop(50%, #beb9b2), color-stop(50%, #edf2b0), color-stop(100%, #edf2b0));
  background: -webkit-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #edf2b0 50%, #edf2b0 100%);
  background: -o-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #edf2b0 50%, #edf2b0 100%);
  background: -ms-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #edf2b0 50%, #edf2b0 100%);
  background: linear-gradient(135deg, #beb9b2 0%, #beb9b2 50%, #edf2b0 50%, #edf2b0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#beb9b2', endColorstr='#edf2b0', GradientType=1);
}
.ms-cost.ms-2u {
  background: #beb9b2;
  background: -moz-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #b5cde3 50%, #b5cde3 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #beb9b2), color-stop(50%, #beb9b2), color-stop(50%, #b5cde3), color-stop(100%, #b5cde3));
  background: -webkit-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #b5cde3 50%, #b5cde3 100%);
  background: -o-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #b5cde3 50%, #b5cde3 100%);
  background: -ms-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #b5cde3 50%, #b5cde3 100%);
  background: linear-gradient(135deg, #beb9b2 0%, #beb9b2 50%, #b5cde3 50%, #b5cde3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#beb9b2', endColorstr='#b5cde3', GradientType=1);
}
.ms-cost.ms-2b {
  background: #beb9b2;
  background: -moz-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #9c9188 50%, #9c9188 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #beb9b2), color-stop(50%, #beb9b2), color-stop(50%, #9c9188), color-stop(100%, #9c9188));
  background: -webkit-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #9c9188 50%, #9c9188 100%);
  background: -o-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #9c9188 50%, #9c9188 100%);
  background: -ms-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #9c9188 50%, #9c9188 100%);
  background: linear-gradient(135deg, #beb9b2 0%, #beb9b2 50%, #9c9188 50%, #9c9188 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#beb9b2', endColorstr='#9c9188', GradientType=1);
}
.ms-cost.ms-2r {
  background: #beb9b2;
  background: -moz-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #db8664 50%, #db8664 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #beb9b2), color-stop(50%, #beb9b2), color-stop(50%, #db8664), color-stop(100%, #db8664));
  background: -webkit-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #db8664 50%, #db8664 100%);
  background: -o-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #db8664 50%, #db8664 100%);
  background: -ms-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #db8664 50%, #db8664 100%);
  background: linear-gradient(135deg, #beb9b2 0%, #beb9b2 50%, #db8664 50%, #db8664 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#beb9b2', endColorstr='#db8664', GradientType=1);
}
.ms-cost.ms-2g {
  background: #beb9b2;
  background: -moz-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #93b483 50%, #93b483 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #beb9b2), color-stop(50%, #beb9b2), color-stop(50%, #93b483), color-stop(100%, #93b483));
  background: -webkit-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #93b483 50%, #93b483 100%);
  background: -o-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #93b483 50%, #93b483 100%);
  background: -ms-linear-gradient(-45deg, #beb9b2 0%, #beb9b2 50%, #93b483 50%, #93b483 100%);
  background: linear-gradient(135deg, #beb9b2 0%, #beb9b2 50%, #93b483 50%, #93b483 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#beb9b2', endColorstr='#93b483', GradientType=1);
}
.ms-cost.ms-p:before,
.ms-cost.ms-wp:before,
.ms-cost.ms-up:before,
.ms-cost.ms-bp:before,
.ms-cost.ms-rp:before,
.ms-cost.ms-gp:before {
  display: inline-block;
  -moz-transform: scale(1.2, 1.2);
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.ms-cost.ms-tap-alt:before {
  display: inline-block;
  -moz-transform: scale(1.2, 1.2);
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
  padding-left: 0.06em;
  padding-bottom: 0.1em;
}
.ms-cost.ms-s:before {
  color: #fff;
  -webkit-text-stroke: 2px #fff;
  font-size: 0.85em;
  top: -0.05em;
  position: relative;
  display: inline-block;
}
.ms-cost.ms-s:after {
  content: "\e619";
  position: absolute;
  color: #333;
  margin-left: -0.9em;
  font-size: 1.1em;
}
.ms-cost.ms-untap {
  background-color: #111;
  color: #fff;
}
.ms-cost.ms-shadow {
  box-shadow: -0.06em 0.07em 0 #111, 0 0.06em 0 #111;
}
.ms-cost.ms-shadow.ms-untap {
  box-shadow: -0.06em 0.07em 0 #fff, 0 0.06em 0 #fff;
}
/**
 * Split costs (separate handling) */
.ms-split {
  position: relative;
  width: 1.3em;
  height: 1.3em;
}
.ms-split:before,
.ms-split:after {
  font-size: 0.55em !important;
  position: absolute;
}
.ms-split:before {
  top: -0.38em;
  left: 0.28em;
}
.ms-split:after {
  top: 0.5em;
  left: 1em;
}
/**
 * Half costs */
span.ms-half {
  width: 0.675em;
  overflow: hidden;
  display: inline-block;
  margin-left: 0.675em;
}
span.ms-half > .ms-cost {
  margin-left: -0.675em;
}
.ms-half {
  width: 0.675em;
  margin-left: 0.675em;
  overflow: hidden;
  border-radius: 0 1em 1em 0;
}
.ms-half:before {
  margin-left: -0.65em;
}
/**
 * Un-set costs */
.ms-100 {
  width: 2.4em;
}
.ms-1000000 {
  width: 5.4em;
}
/**
 * Planeswalker symbols */
.ms-loyalty-up,
.ms-loyalty-down,
.ms-loyalty-zero,
.ms-loyalty-start {
  color: #111;
  font-size: 21px;
  position: relative;
  text-align: center;
  line-height: 0.9em;
}
.ms-loyalty-start {
  font-size: 25px;
}
.ms-loyalty-0:after,
.ms-loyalty-1:after,
.ms-loyalty-2:after,
.ms-loyalty-3:after,
.ms-loyalty-4:after,
.ms-loyalty-5:after,
.ms-loyalty-6:after,
.ms-loyalty-7:after,
.ms-loyalty-8:after,
.ms-loyalty-9:after,
.ms-loyalty-10:after,
.ms-loyalty-11:after,
.ms-loyalty-12:after,
.ms-loyalty-13:after,
.ms-loyalty-14:after,
.ms-loyalty-15:after,
.ms-loyalty-16:after,
.ms-loyalty-17:after,
.ms-loyalty-18:after,
.ms-loyalty-19:after,
.ms-loyalty-20:after,
.ms-loyalty-25:after,
.ms-loyalty-x:after {
  color: #fff;
  display: inline-block;
  font-size: 0.5em;
  font-family: MPlantin, Garamond, Palatino, 'Times New Roman', Times, serif;
  position: absolute;
  left: 0;
  line-height: 2em;
  width: 100%;
  text-align: center;
}
.ms-loyalty-0:after {
  content: "0";
}
.ms-loyalty-up.ms-loyalty-1:after {
  content: "+1";
}
.ms-loyalty-up.ms-loyalty-2:after {
  content: "+2";
}
.ms-loyalty-up.ms-loyalty-3:after {
  content: "+3";
}
.ms-loyalty-up.ms-loyalty-4:after {
  content: "+4";
}
.ms-loyalty-up.ms-loyalty-5:after {
  content: "+5";
}
.ms-loyalty-up.ms-loyalty-6:after {
  content: "+6";
}
.ms-loyalty-up.ms-loyalty-7:after {
  content: "+7";
}
.ms-loyalty-up.ms-loyalty-8:after {
  content: "+8";
}
.ms-loyalty-up.ms-loyalty-9:after {
  content: "+9";
}
.ms-loyalty-up.ms-loyalty-10:after {
  content: "+10";
}
.ms-loyalty-up.ms-loyalty-11:after {
  content: "+11";
}
.ms-loyalty-up.ms-loyalty-12:after {
  content: "+12";
}
.ms-loyalty-up.ms-loyalty-13:after {
  content: "+13";
}
.ms-loyalty-up.ms-loyalty-14:after {
  content: "+14";
}
.ms-loyalty-up.ms-loyalty-15:after {
  content: "+15";
}
.ms-loyalty-up.ms-loyalty-16:after {
  content: "+16";
}
.ms-loyalty-up.ms-loyalty-17:after {
  content: "+17";
}
.ms-loyalty-up.ms-loyalty-18:after {
  content: "+18";
}
.ms-loyalty-up.ms-loyalty-19:after {
  content: "+19";
}
.ms-loyalty-up.ms-loyalty-20:after {
  content: "+20";
}
.ms-loyalty-up.ms-loyalty-25:after {
  content: "+25";
}
.ms-loyalty-up.ms-loyalty-x:after {
  content: "+X";
}
.ms-loyalty-start.ms-loyalty-1:after {
  content: "1";
}
.ms-loyalty-start.ms-loyalty-2:after {
  content: "2";
}
.ms-loyalty-start.ms-loyalty-3:after {
  content: "3";
}
.ms-loyalty-start.ms-loyalty-4:after {
  content: "4";
}
.ms-loyalty-start.ms-loyalty-5:after {
  content: "5";
}
.ms-loyalty-start.ms-loyalty-6:after {
  content: "6";
}
.ms-loyalty-start.ms-loyalty-7:after {
  content: "7";
}
.ms-loyalty-start.ms-loyalty-8:after {
  content: "8";
}
.ms-loyalty-start.ms-loyalty-9:after {
  content: "9";
}
.ms-loyalty-start.ms-loyalty-10:after {
  content: "10";
}
.ms-loyalty-start.ms-loyalty-11:after {
  content: "11";
}
.ms-loyalty-start.ms-loyalty-12:after {
  content: "12";
}
.ms-loyalty-start.ms-loyalty-13:after {
  content: "13";
}
.ms-loyalty-start.ms-loyalty-14:after {
  content: "14";
}
.ms-loyalty-start.ms-loyalty-15:after {
  content: "15";
}
.ms-loyalty-start.ms-loyalty-16:after {
  content: "16";
}
.ms-loyalty-start.ms-loyalty-17:after {
  content: "17";
}
.ms-loyalty-start.ms-loyalty-18:after {
  content: "18";
}
.ms-loyalty-start.ms-loyalty-19:after {
  content: "19";
}
.ms-loyalty-start.ms-loyalty-20:after {
  content: "20";
}
.ms-loyalty-start.ms-loyalty-25:after {
  content: "25";
}
.ms-loyalty-start.ms-loyalty-x:after {
  content: "X";
}
.ms-loyalty-down:after {
  line-height: 1.8em;
}
.ms-loyalty-down.ms-loyalty-1:after {
  content: "-1";
}
.ms-loyalty-down.ms-loyalty-2:after {
  content: "-2";
}
.ms-loyalty-down.ms-loyalty-3:after {
  content: "-3";
}
.ms-loyalty-down.ms-loyalty-4:after {
  content: "-4";
}
.ms-loyalty-down.ms-loyalty-5:after {
  content: "-5";
}
.ms-loyalty-down.ms-loyalty-6:after {
  content: "-6";
}
.ms-loyalty-down.ms-loyalty-7:after {
  content: "-7";
}
.ms-loyalty-down.ms-loyalty-8:after {
  content: "-8";
}
.ms-loyalty-down.ms-loyalty-9:after {
  content: "-9";
}
.ms-loyalty-down.ms-loyalty-10:after {
  content: "-10";
}
.ms-loyalty-down.ms-loyalty-11:after {
  content: "-11";
}
.ms-loyalty-down.ms-loyalty-12:after {
  content: "-12";
}
.ms-loyalty-down.ms-loyalty-13:after {
  content: "-13";
}
.ms-loyalty-down.ms-loyalty-14:after {
  content: "-14";
}
.ms-loyalty-down.ms-loyalty-15:after {
  content: "-15";
}
.ms-loyalty-down.ms-loyalty-16:after {
  content: "-16";
}
.ms-loyalty-down.ms-loyalty-17:after {
  content: "-17";
}
.ms-loyalty-down.ms-loyalty-18:after {
  content: "-18";
}
.ms-loyalty-down.ms-loyalty-19:after {
  content: "-19";
}
.ms-loyalty-down.ms-loyalty-20:after {
  content: "-20";
}
.ms-loyalty-down.ms-loyalty-25:after {
  content: "-25";
}
.ms-loyalty-down.ms-loyalty-x:after {
  content: "-X";
}
/**
 * Saga loyalty */
.ms-saga-1:after,
.ms-saga-2:after,
.ms-saga-3:after,
.ms-saga-4:after,
.ms-saga-5:after {
  color: #fff;
  display: inline-block;
  font-size: 0.6em;
  font-family: MPlantin, Garamond, Palatino, 'Times New Roman', Times, serif;
  position: absolute;
  left: 0;
  line-height: 1.75em;
  width: 100%;
  text-align: center;
}
.ms-saga-1:after {
  content: "I";
}
.ms-saga-2:after {
  content: "II";
}
.ms-saga-3:after {
  content: "III";
}
.ms-saga-4:after {
  content: "IV";
}
.ms-saga-5:after {
  content: "V";
}
/**
 * Double faced cards */
.ms-dfc {
  color: #111;
  border: 0.05em solid #111;
  border-radius: 2em;
  padding: 0.025em;
}
/*
 * Larger sizes */
.ms-2x {
  font-size: 1.75em;
}
.ms-3x {
  font-size: 2.25em;
}
.ms-4x {
  font-size: 3em;
}
.ms-5x {
  font-size: 3.75em;
}
.ms-6x {
  font-size: 4.5em;
}
/**
 * Mana */
.ms-w:before {
  content: "\e600";
}
.ms-u:before {
  content: "\e601";
}
.ms-b:before {
  content: "\e602";
}
.ms-r:before {
  content: "\e603";
}
.ms-g:before {
  content: "\e604";
}
.ms-0:before {
  content: "\e605";
}
.ms-1:before {
  content: "\e606";
}
.ms-2:before {
  content: "\e607";
}
.ms-3:before {
  content: "\e608";
}
.ms-4:before {
  content: "\e609";
}
.ms-5:before {
  content: "\e60a";
}
.ms-6:before {
  content: "\e60b";
}
.ms-7:before {
  content: "\e60c";
}
.ms-8:before {
  content: "\e60d";
}
.ms-9:before {
  content: "\e60e";
}
.ms-10:before {
  content: "\e60f";
}
.ms-11:before {
  content: "\e610";
}
.ms-12:before {
  content: "\e611";
}
.ms-13:before {
  content: "\e612";
}
.ms-14:before {
  content: "\e613";
}
.ms-15:before {
  content: "\e614";
}
.ms-16:before {
  content: "\e62a";
}
.ms-17:before {
  content: "\e62b";
}
.ms-18:before {
  content: "\e62c";
}
.ms-19:before {
  content: "\e62d";
}
.ms-20:before {
  content: "\e62e";
}
.ms-x:before {
  content: "\e615";
}
.ms-y:before {
  content: "\e616";
}
.ms-z:before {
  content: "\e617";
}
.ms-p:before,
.ms-wp:before,
.ms-up:before,
.ms-bp:before,
.ms-rp:before,
.ms-gp:before {
  content: "\e618";
}
.ms-s:before {
  content: "\e619";
}
.ms-c:before {
  content: "\e904";
}
.ms-e:before,
.ms-energy:before {
  content: "\e907";
}
/**
 * Tap/roll symbols */
.ms-tap:before {
  content: "\e61a";
}
.ms-untap:before {
  content: "\e61b";
}
.ms-tap-alt:before {
  content: "\e61c";
}
.ms-chaos:before {
  content: "\e61d";
}
.ms-1-2:before {
  content: "\e902";
}
.ms-infinity:before {
  content: "\e903";
}
/**
 * Card types */
.ms-artifact:before {
  content: "\e61e";
}
.ms-creature:before {
  content: "\e61f";
}
.ms-enchantment:before {
  content: "\e620";
}
.ms-instant:before {
  content: "\e621";
}
.ms-land:before {
  content: "\e622";
}
.ms-planeswalker:before {
  content: "\e623";
}
.ms-sorcery:before {
  content: "\e624";
}
.ms-multiple:before {
  content: "\e925";
}
.ms-saga:before {
  content: "\e92a";
}
.ms-acorn:before {
  content: "\e929";
}
/**
 * Split symbols */
.ms-wu:before,
.ms-wb:before,
.ms-rw:after,
.ms-gw:after,
.ms-2w:after {
  content: "\e600";
}
.ms-ub:before,
.ms-ur:before,
.ms-wu:after,
.ms-gu:after,
.ms-2u:after {
  content: "\e601";
}
.ms-br:before,
.ms-bg:before,
.ms-wb:after,
.ms-ub:after,
.ms-2b:after {
  content: "\e602";
}
.ms-rw:before,
.ms-rg:before,
.ms-ur:after,
.ms-br:after,
.ms-2r:after {
  content: "\e603";
}
.ms-gw:before,
.ms-gu:before,
.ms-bg:after,
.ms-rg:after,
.ms-2g:after {
  content: "\e604";
}
.ms-2w:before,
.ms-2u:before,
.ms-2b:before,
.ms-2r:before,
.ms-2g:before {
  content: "\e607";
}
/**
 * Un-set symbols */
.ms-100:before {
  content: "\e900";
}
.ms-1000000:before {
  content: "\e901";
}
/**
 * Planeswalker symbols */
.ms-loyalty-up:before {
  content: "\e627";
}
.ms-loyalty-down:before {
  content: "\e625";
}
.ms-loyalty-zero:before {
  content: "\e626";
}
.ms-loyalty-start:before {
  content: "\e628";
}
/**
 * Other */
.ms-flashback:before {
  content: "\e629";
}
.ms-dfc-night:before {
  content: "\e905";
}
.ms-dfc-day:before {
  content: "\e906";
}
.ms-dfc-ignite:before {
  content: "\e908";
}
.ms-dfc-spark:before {
  content: "\e909";
}
.ms-dfc-emrakul:before {
  content: "\e90a";
}
.ms-dfc-moon:before {
  content: "\e90b";
}
.ms-dfc-enchantment:before {
  content: "\e920";
}
.ms-power:before {
  content: "\e921";
}
.ms-toughness:before {
  content: "\e922";
}
.ms-artist-brush:before {
  content: "\e923";
}
.ms-artist-nib:before {
  content: "\e924";
}
/**
 * Guilds and Clans */
.ms-guild-azorius:before {
  content: "\e90c";
}
.ms-guild-boros:before {
  content: "\e90d";
}
.ms-guild-dimir:before {
  content: "\e90e";
}
.ms-guild-golgari:before {
  content: "\e90f";
}
.ms-guild-gruul:before {
  content: "\e910";
}
.ms-guild-izzet:before {
  content: "\e911";
}
.ms-guild-orzhov:before {
  content: "\e912";
}
.ms-guild-rakdos:before {
  content: "\e913";
}
.ms-guild-selesnya:before {
  content: "\e914";
}
.ms-guild-simic:before {
  content: "\e915";
}
.ms-clan-abzan:before {
  content: "\e916";
}
.ms-clan-jeskai:before {
  content: "\e917";
}
.ms-clan-mardu:before {
  content: "\e918";
}
.ms-clan-sultai:before {
  content: "\e919";
}
.ms-clan-temur:before {
  content: "\e91a";
}
.ms-clan-atarka:before {
  content: "\e91b";
}
.ms-clan-dromoka:before {
  content: "\e91c";
}
.ms-clan-kolaghan:before {
  content: "\e91d";
}
.ms-clan-ojutai:before {
  content: "\e91e";
}
.ms-clan-silumgar:before {
  content: "\e91f";
}
/**
 * Poleis */
.ms-polis-setessa:before {
  content: "\e926";
}
.ms-polis-akros:before {
  content: "\e927";
}
.ms-polis-meletis:before {
  content: "\e928";
}
